'use client';

import { Box, Button, Flex, Hide, Portal } from '@chakra-ui/react';
import { CfCard, layoutMinWidth, uiColors, useCfBreakpoint } from '@cryptofi/core-ui';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import {
  AssetsList,
  CreateAccountCard,
  IntroductionCard,
  InvestmentsList,
  LatestTrade,
  LearnSection,
  PageLayout,
  PerformanceOverTime,
} from '~/components';
import { investButtonLabels } from '~/constants';
import { AllAssetIds, ChartTimeRange } from '~/customTypes';
import {
  useFeatureSetEnabled,
  useGetTokenPrices,
  useGetUser,
  useGlobalModalContext,
  useOnboardingInfo,
  usePostTelemetryEvent,
  useUserInvestments,
} from '~/hooks';
import { getExploreAssetsSecurities } from '~/utils';
import { hasPerformanceData, updateInvestmentValueTimeSeries } from '~/utils/investments';

export default function HomePage() {
  const user = useGetUser();
  const investments = useUserInvestments();

  const [selectedTimeRange, setSelectedTimeRange] = useState<ChartTimeRange>('pastDay');
  const onboardingInfo = useOnboardingInfo();
  const { isOnboarded, isRejectedOrPending, currentOnboardingStatus, hasNewTerms } = onboardingInfo;
  const { isEnabled } = useFeatureSetEnabled();
  const { onOpenModal, onOnboardingModalOpen } = useGlobalModalContext();

  useEffect(() => {
    if (user?.data) {
      mixpanel.identify(`${user.data?.fiId}-${user.data?.userAccountId}`);
    }
  }, [user?.data]);

  const tokenPrices = useGetTokenPrices();
  const { isBelowLargeBreakpoint } = useCfBreakpoint();
  const updatedInvestmentValueTimeSeries = updateInvestmentValueTimeSeries({
    investmentValueTimeSeries: user.data?.balance?.investmentHistoricalValue,
    allInvestmentsBalance: investments.data.totalBalances.allInvestments.toString(),
  });
  const chartData = updatedInvestmentValueTimeSeries?.[selectedTimeRange] || [];
  const [defaultAssetId, setDefaultAssetId] = useState<AllAssetIds | undefined>();
  const featuredSecurities = getExploreAssetsSecurities(user.data?.securitiesRiskProfile);

  const showOnboarding = (currentOnboardingStatus && !isOnboarded && !hasNewTerms) || isRejectedOrPending;

  const tradePortalHeight = '5rem';
  const ctaBtnLabel =
    investments.data.totalBalances.allInvestments > 0 ? investButtonLabels.trade : investButtonLabels.invest;
  const { trackEvent } = usePostTelemetryEvent();

  // Handle "Investment" button clicks; Fire tracking event, then the original onClick action
  const handleInvestButtonClick = () => {
    trackEvent(TelemetryClientSideEventsEnum.ClickedCryptoHomeInvestButtonClient);
    onOpenModal();
  };

  // determine the default asset to show in the invest modal, after feature set is loaded
  useEffect(() => {
    if (!defaultAssetId && isEnabled(['securities'])) {
      setDefaultAssetId(featuredSecurities.at(0));
    }

    if (!defaultAssetId && !isEnabled(['securities']) && isEnabled(['crypto']) && tokenPrices.data) {
      setDefaultAssetId(tokenPrices.data[0]?.baseToken);
    }
  }, [defaultAssetId, tokenPrices.data, featuredSecurities, isEnabled]);

  // when terms have been updated after the user has signed them, direct user to onboarding flow to sign new terms.
  useEffect(() => {
    if (hasNewTerms) {
      onOnboardingModalOpen();
    }
  }, [hasNewTerms, onOnboardingModalOpen]);

  return (
    <PageLayout
      navHeading="Home"
      hideTopNavBar={!isBelowLargeBreakpoint}
      bgColor={uiColors.lighthouse()}
      defaultAssetId={defaultAssetId}
    >
      <Flex gap="3" w="full" flexDir={{ base: 'column', lg: 'row' }}>
        <Flex flexDir="column" gap="inherit" flexGrow="1">
          {hasPerformanceData({ user: user.data, investmentCount: investments.data.investmentCount }) && (
            <PerformanceOverTime
              selectedTimeRange={selectedTimeRange}
              setSelectedTimeRange={setSelectedTimeRange}
              chartData={chartData}
              totalValue={investments.data.totalBalances.allInvestments}
              isLoaded={investments.isSuccess}
              valueType="portfolio"
              gainLossAmount={investments.data.totalBalances.allGainLoss.amount}
              gainLossPercent={investments.data.totalBalances.allGainLoss.percent}
              onOpenModal={onOpenModal}
            />
          )}

          {showOnboarding && (
            <>
              <CreateAccountCard />

              <IntroductionCard />
            </>
          )}

          {isOnboarded && <InvestmentsList />}

          <LearnSection />
        </Flex>

        <Flex flexDir="column" gap="inherit" flexBasis="33%" flexShrink="0">
          <CfCard p="0">
            <AssetsList />
          </CfCard>

          <CfCard>
            <LatestTrade />
          </CfCard>
        </Flex>
      </Flex>

      <Hide above="lg">
        <Portal>
          <Flex
            position="fixed"
            bottom="0"
            zIndex="2"
            bg="white"
            w="full"
            p="4"
            borderTop="solid 1px"
            borderColor={uiColors.coolElegance()}
            minW={layoutMinWidth}
            h={tradePortalHeight}
          >
            <Button w="full" h="full" onClick={handleInvestButtonClick}>
              {ctaBtnLabel}
            </Button>
          </Flex>

          <Box mt={tradePortalHeight} />
        </Portal>
      </Hide>
    </PageLayout>
  );
}
